import React from 'react';
import { graphql } from 'gatsby';

import MainContentWrapper from '../../components/MainContentWrapper';
import SEO from '../../components/SEO';
import MarkdownHTMLAst from '../../components/MarkdownHTMLAst';

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <SEO seo={{
        title: 'Privacy Policy',
        metas: [
          {
            name: 'description',
            content: 'DRD event decorations privacy policy',
          },
        ],
      }} />
      <MainContentWrapper>
        <MarkdownHTMLAst data={data.markdownRemark.htmlAst.children} />
      </MainContentWrapper>
    </>
  );
}

export const query = graphql`
  query privacyPolicyQuery {
    markdownRemark(frontmatter: {type: {eq: "privacy policy"}}) {
      htmlAst
    }
  }
`;

export default PrivacyPolicy;